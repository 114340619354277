@font-face {
  font-family: 'Gotham Bold';
  src: local('Gotham Bold'), url('../fonts/Gotham-Bold.ttf') format('ttf');
}
@font-face {
  font-family: 'Gotham Book';
  src: local('Gotham Book'), url('../fonts/Gotham-Book.ttf') format('ttf');
}

// Mixins
@mixin flex($justify, $align, $direction) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

html {
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.flask-icon {
  g {
    fill: #2f6189;
  }
  width: 27px;
  height: 27px;
}

/* Horizontal scroll slider */
.menu-item {
  color: #999999;
  padding: 0 20px;
  margin: 5px;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-wrapper {
  width: 100%;
}

.menu-item-wrapper:focus {
  outline: none;
}

.menu-item.active {
  color: white;
  font-weight: 600;
  background: #2f6189;
  border-radius: 10px;
}

.scroll-menu-arrow {
  padding: 10px;
  cursor: pointer;
}

.horizontal-menu {
  justify-content: space-between;
}

// Fixes issue with custom styles (JS in CSS) not overriding default props
#dashboard-sidebar > .MuiDrawer-paper {
  position: relative;
  z-index: 10;
}

// React weekly scheduler
.styles-module_calendar__tGgRK {
  margin-right: 1rem;
}

// Tooltip
.tooltip {
  padding: 0;
  transition: height 0.5s ease-in-out;
  button {
    padding: 0.3125rem 0.5625rem;
    appearance: none;
    background: black;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    .icon {
      color: white;
    }
  }
}

// Fix for Doctor List Chip

span.MuiChip-label {
  color: white !important;
}

// Booking Date Picker

@media (min-width: 768px) {
  .react-datepicker {
    font-size: 1.3em !important;

    .react-datepicker__header {
      padding-top: 0.8em;
    }
    .react-datepicker__month {
      margin: 0.4em 1em;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 1.9em;
      line-height: 1.9em;
      margin: 0.166em;
    }
    .react-datepicker__current-month {
      font-size: 1em;
    }
    .react-datepicker__navigation {
      top: 1em;
      line-height: 1.7em;
      border: 0.45em solid transparent;
    }
    .react-datepicker__navigation--previous {
      border-right-color: #ccc;
      left: 1em;
    }
    .react-datepicker__navigation--next {
      border-left-color: #ccc;
      right: 1em;
    }

    .react-datepicker-time__header {
      font-size: 0.944em;
    }

    .react-datepicker__navigation--next--with-time {
      right: 80px;
    }

    .react-datepicker__time-list-item {
      font-size: 0.8em;
    }
  }
}

// Livees Checkout
.livees-wrapper {
  margin-top: 5rem;
  h1 {
    text-align: center;
  }
  form {
    @include flex(center, center, column);
  }
  .iframe-wrapper {
    margin-top: 3rem;
    padding: 1rem;
    text-align: center;
  }
  .livees-goback {
    margin-top: 2rem;
    text-align: center;
    p {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (min-width: 320px) {
  #livees-iframe {
    height: 1200px !important;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  #livees-iframe {
    height: 1200px !important;
  }
}
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .iframe-wrapper {
    max-width: 700px;
    margin: 0 auto;
    #livees-iframe {
      height: 1200px !important;
    }
  }
}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  #livees-iframe {
    height: 1200px !important;
  }
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  #livees-iframe {
    height: 1200px !important;
  }
}
